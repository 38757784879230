import React from "react";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./Loading.module.css";

type PropTypes = {
  visible?: boolean;
};

const defaultProps: Partial<PropTypes> = {
  visible: false,
};

const Loading = ({ visible }: PropTypes) => {
  if (!visible) {
    return null;
  }

  return (
    <Modal open={visible} aria-describedby="loading spinner" data-qa="loading-modal">
      <div className={styles.box}>
        <CircularProgress size="72px" sx={{ color: "#75FFD6" }} />
      </div>
    </Modal>
  );
};

Loading.defaultProps = defaultProps;

export default Loading;
