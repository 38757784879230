import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

/*
 *This client will create new ApolloClient
 * and will send the GraphQL url as link.
 * REACT_APP_CMS_GRAPHQL_API_URL is declared as variable at root/.env.production
 */

const GRAPHQL_URL = process.env.REACT_APP_CMS_GRAPHQL_API_URL;
const webSiteClient = new ApolloClient({
  ssrMode: true,
  link: new HttpLink({ uri: GRAPHQL_URL }),
  cache: new InMemoryCache(),
});

export default webSiteClient;
