import { Cta } from "../types";

const EvnVarRegex = /\${(\w*)\}/g;

export const replaceEnvVar = (text: string) => {
  const match = EvnVarRegex.exec(text);

  if (!match) {
    return text;
  }

  const envKeyPattern = match[0] ?? "";
  const envKey = match[1] ?? "";
  const envValue = process.env[envKey] ?? "";

  return text.replace(envKeyPattern, envValue);
};

export const getUrl = (cta?: Cta) => {
  const ctaLink = cta?.url ?? cta?.page?.uri ?? cta?.file?.link ?? "";

  if (!ctaLink) {
    return null;
  }

  return { url: replaceEnvVar(ctaLink), isFile: !!cta?.file?.link };
};
