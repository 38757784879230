import { Keys } from "../constants/localStorage";
import { CountryOfOperation } from "../types";
import { replaceEnvVar } from "./cta";

export const persistAndRedirect = (option: CountryOfOperation) => {
  localStorage.setItem(Keys.Language, option.language);
  localStorage.setItem(Keys.MenuName, option.menu);
  localStorage.setItem(Keys.Country, option.country);

  window.location.assign(replaceEnvVar(option?.url) ?? "#");
};

export const getDefaultCoutryOfOperation = (countriesOfOperation: CountryOfOperation[]) => {
  const [defaultOption] = countriesOfOperation;
  const { origin } = window.location;
  const tld = origin.slice(origin.lastIndexOf(".") + 1);

  return countriesOfOperation.find(({ country }) => country === tld) || defaultOption;
};
