import React, { ComponentType, Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "react-error-boundary";
import { ApolloProvider } from "@apollo/client";
import { Cookies, getCookieConsentValue } from "react-cookie-consent";
import ErrorTemplate from "./routes/error-template";
import webSiteClient from "./api/GraphQLWebSiteClient";
import { ThemeProvider } from "./components/ThemeProvider";
import { Loading } from "./components/Loading";
import { Error } from "./components/Error";
import useMenu from "./hooks/useMenu";
import store from "./app/store";
import { Keys } from "./constants/localStorage";
import { getDefaultCoutryOfOperation, persistAndRedirect } from "./utils/countryOfOperation";
import { CookieConsent } from "./components/CookieConsent";
import initAnalytics from "./tracking";

const language = localStorage.getItem(Keys.Language) || "helvengo_ch_de";
const menu = localStorage.getItem(Keys.MenuName) || "de";

const componentLoader = (
  lazyComponent: () => Promise<{ default: ComponentType<unknown> }>,
  retriesLeft = 5,
  interval = 1000,
) =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);

            return;
          }
          componentLoader(lazyComponent, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const GenericPage = React.lazy(() =>
  // @ts-expect-error typing mismatch
  componentLoader(() => import("./pages/GenericPage/GenericPage")),
);
const ContactPage = React.lazy(() =>
  // @ts-expect-error typing mismatch
  componentLoader(() => import("./pages/GenericPage/ContactPage")),
);

const WebSiteContent = () => {
  const { getMenus, data, isLoading, error } = useMenu();
  const [isLangPersisted, setIsLangPersisted] = useState<boolean>(false);

  const menus = data?.menuItems?.nodes;
  const allowedRoutes = data?.pages;
  const countriesOfOperation = useMemo(
    () => data.countriesOfOperations,
    [data.countriesOfOperations],
  );
  const allCountries = useMemo(() => data.allCountries, [data.allCountries]);

  const onAcceptCookie = () => {
    if (process.env.REACT_APP_TM_TRACKING_ID) {
      initAnalytics();
    }
  };

  const onDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const getAllMenus = () => {
      getMenus();
    };

    getAllMenus();
  }, [getMenus]);

  useEffect(() => {
    if (countriesOfOperation.length) {
      const storageOption = countriesOfOperation.find(
        (option) => option.language === language && option.menu === menu,
      );

      if (!storageOption) {
        const defaultOption = getDefaultCoutryOfOperation(countriesOfOperation);

        persistAndRedirect(defaultOption);
      }

      setIsLangPersisted(true);
    }
  }, [countriesOfOperation]);

  useEffect(() => {
    const isConsent = getCookieConsentValue();

    if (isConsent === "true") {
      onAcceptCookie();
    }
  }, []);

  if (error) {
    return <ErrorTemplate />;
  }

  if (isLoading || !allowedRoutes || !isLangPersisted) {
    return <Loading visible />;
  }

  return (
    <>
      <Routes>
        <Route path="kontakt" element={<ContactPage allCountries={allCountries} />} />
        {allowedRoutes &&
          allowedRoutes?.map((allowedRoute: string) => (
            <Route
              key={allowedRoute}
              path={allowedRoute}
              element={
                <GenericPage
                  template="{allowedRoute.template.templateName}" // TODO
                  menuItems={menus}
                  organization={data?.organization}
                  countriesOfOperation={countriesOfOperation}
                />
              }
            />
          ))}
        <Route path="*" element={<ErrorTemplate />} />
      </Routes>
      <CookieConsent
        analyticsConsent={data?.organization?.analyticsConsent}
        onAccept={onAcceptCookie}
        onDecline={onDeclineCookie}
      />
    </>
  );
};

const WebSite = () => {
  return (
    <ThemeProvider>
      <ErrorBoundary fallback={<Error />}>
        <Provider store={store}>
          <ApolloProvider client={webSiteClient}>
            <HelmetProvider>
              <Suspense fallback={<Loading visible />}>
                <BrowserRouter>
                  <WebSiteContent />
                </BrowserRouter>
              </Suspense>
            </HelmetProvider>
          </ApolloProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default WebSite;
