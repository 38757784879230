import React from "react";
import Grid, { GridProps } from "@mui/material/Grid";

type ContainerPropTypes = Omit<GridProps, "container" | "item" | "xs" | "sm" | "md" | "columns"> & {
  columns?: GridProps["columns"];
};

const containerDefaultProps: Partial<ContainerPropTypes> = {
  columns: { xs: 4, sm: 8, md: 12 },
};

type ItemPropTypes = Omit<GridProps, "container" | "item" | "columns"> & {
  xs?: GridProps["xs"];
  sm?: GridProps["sm"];
  md?: GridProps["md"];
};
const itemDefaultProps: Partial<ItemPropTypes> = {
  xs: 4,
  sm: 8,
  md: 12,
};

const GridContainer = ({ children, columns, ...props }: ContainerPropTypes) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid container columns={columns} {...props}>
      {children}
    </Grid>
  );
};

const GridItem = ({ children, xs, sm, md, ...props }: ItemPropTypes) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Grid item xs={xs} sm={sm} md={md} {...props}>
      {children}
    </Grid>
  );
};

GridContainer.defaultProps = containerDefaultProps;
GridItem.defaultProps = itemDefaultProps;

export { GridContainer, GridItem };
