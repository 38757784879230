import React, { Component } from "react";
import { Error } from "../components/Error";
import Helmet from "../components/Helmet";

class ErrorTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Helmet />
        <Error />
      </>
    );
  }
}

export default ErrorTemplate;
