import React from "react";
import ReactCookieConsent from "react-cookie-consent";
import Typography from "@mui/material/Typography";
import useMatchBreakpoint from "../../hooks/useMatchBreakpoint";
import { AnalyticsConsent } from "../../types/menu";

type PropTypes = {
  analyticsConsent?: AnalyticsConsent;
  onAccept: () => void;
  onDecline: () => void;
};

const defaultProps = {
  analyticsConsent: undefined,
};

const CookieConsent = ({ analyticsConsent, onAccept, onDecline }: PropTypes) => {
  const isBelowMd = useMatchBreakpoint("down-md");
  const isBelowSm = useMatchBreakpoint("down-sm");

  const getMaxWidth = () => {
    if (isBelowSm) {
      return "calc(100% - 40px)";
    }

    if (isBelowMd && !isBelowSm) {
      return "calc(100% - 64px)";
    }

    return "1088px";
  };

  return (
    <ReactCookieConsent
      enableDeclineButton
      style={{
        backgroundColor: "#B3F1DF",
        fontWeight: "700",
        color: "#222222",
        borderRadius: "10px",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: "24px !important",
        width: "100%",
        maxWidth: getMaxWidth(),
      }}
      buttonText={analyticsConsent?.acceptLabel}
      declineButtonText={analyticsConsent?.declineLabel}
      buttonStyle={{
        padding: "8px 16px",
        backgroundColor: "transparent",
        border: "1px solid #000000",
        borderRadius: "10px",
        color: "#000",
        fontWeight: "700",
        fontFamily: "Roboto, Arial",
      }}
      declineButtonStyle={{
        padding: "8px 16px",
        backgroundColor: "transparent",
        border: "1px solid #000000",
        borderRadius: "10px",
        color: "#000",
        fontWeight: "700",
        fontFamily: "Roboto, Arial",
        margin: "15px 0 15px 15px",
      }}
      onAccept={onAccept}
      onDecline={onDecline}>
      <Typography align="left" variant="h5" display="inline-flex" fontSize="18px">
        {analyticsConsent?.title}
      </Typography>
      <span style={{ marginLeft: "16px" }}>
        <Typography align="left" variant="body1" display="inline-flex" component="span">
          {analyticsConsent?.text}
        </Typography>
      </span>
    </ReactCookieConsent>
  );
};

CookieConsent.defaultProps = defaultProps;

export default CookieConsent;
