import TagManager from "react-gtm-module";

const initAnalytics = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_TM_TRACKING_ID,
  };

  TagManager.initialize(tagManagerArgs);
};

export const GAPageView = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      pagePath: window.location.pathname,
      pageTitle: window.location.search,
    },
  });
};

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const GAEvent = (category, action, label) => {
  TagManager.dataLayer({
    dataLayer: {
      event: category,
      pagePath: action,
      pageTitle: label,
    },
  });
};

export default initAnalytics;
