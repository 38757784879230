import React from "react";
import PropTypes from "prop-types";
import { Helmet as HelmetReact } from "react-helmet-async";

const Helmet = (props) => {
  const {
    title,
    metaDesc,
    type,
    twitterDescription,
    twitterImage,
    twitterTitle,
    locale,
    opengraphImage,
  } = props;

  return (
    <HelmetReact>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaDesc} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:image" content={opengraphImage && opengraphImage.sourceUrl} />
      <meta property="twitter:url" content={window.location.href} />
      <meta property="twitter:title" content={twitterTitle} />
      <meta property="twitter:description" content={twitterDescription} />
      <meta property="twitter:image" content={twitterImage && twitterImage.sourceUrl} />
    </HelmetReact>
  );
};

Helmet.propTypes = {
  title: PropTypes.string,
  metaDesc: PropTypes.string,
  type: PropTypes.string,
  locale: PropTypes.string,
  twitterImage: PropTypes.shape({
    sourceUrl: PropTypes.string,
  }),
  twitterDescription: PropTypes.string,
  twitterTitle: PropTypes.string,
  opengraphImage: PropTypes.shape({
    sourceUrl: PropTypes.string,
  }),
};

Helmet.defaultProps = {
  title: "",
  metaDesc: "",
  type: "",
  locale: "",
  twitterImage: null,
  twitterDescription: "",
  twitterTitle: "",
  opengraphImage: null,
};

export default Helmet;
