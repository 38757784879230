import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

type Breakpoints = "up-md" | "down-md" | "up-sm" | "down-sm" | "up-xs";

const useMatchBreakpoint = (breakpoint: Breakpoints) => {
  const theme = useTheme();

  const [direction, screen] = breakpoint.split("-");

  const matcher = direction === "up" ? theme.breakpoints.up : theme.breakpoints.down;
  const isMatch = useMediaQuery(matcher(screen as Breakpoint));

  return isMatch;
};

export default useMatchBreakpoint;
