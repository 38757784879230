import React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { fontWeight, themeConfig } from "./themeConfigs";

const addCssVars = () => {
  Object.keys(fontWeight).forEach((key) => {
    document.documentElement.style.setProperty(`--weight-${key}`, fontWeight[key]);
  });
};

const mainTheme = createTheme(themeConfig);

const ThemeProvider = ({ children }) => {
  addCssVars();

  return (
    <MuiThemeProvider theme={mainTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
