import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { GridContainer, GridItem } from "../Grid";
import ErrorImage from "../../assets/images/new/error.png";

const title = "Hmmm... Sie haben sich wohl im Cyberspace verirrt!";
const message =
  "Die von Ihnen gesuchte Seite kann leider nicht gefunden werden. Wir arbeiten daran, den Fehler zu beheben.";
const buttonLabel = "Zurück zur Startseite";
const link = "/";

const ErrorPage = () => {
  return (
    <GridContainer justifyContent="center" px={{ xs: "16px", sm: "20px", md: "20px" }}>
      <GridItem sm={6} md={6} textAlign="center">
        <GridItem pt="80px" pb="16px">
          <Typography textAlign="center" variant="h3">
            {title}
          </Typography>
        </GridItem>
        <GridItem pb="40px">
          <Typography textAlign="center" variant="body1">
            {message}
          </Typography>
        </GridItem>
        <GridItem pb="32px">
          <img src={ErrorImage} alt="Error" width="100%" />
        </GridItem>
        <GridItem textAlign="center">
          <Link href={link} color="#000">
            {buttonLabel}
          </Link>
        </GridItem>
      </GridItem>
    </GridContainer>
  );
};

export default ErrorPage;
