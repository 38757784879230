import { useCallback } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Keys } from "../constants/localStorage";
import { Data, Options } from "../types";
import { CountryInfo } from "../types/menu";

const DefaultHome = "/";

const getOptionsQuery = gql`
  query getOptions {
    acfOptionsOptions {
      Options {
        countries {
          country
          languages {
            language
            email
            emailLabel
            fieldGroupName
            contactForm
            impressum
            impressumLabel
            phone
            phoneLabel
            termsConditions
            termsConditionsLabel
            address
            addressLabel
            analyticsConsent {
              acceptLabel
              declineLabel
              text
              title
            }
          }
        }
        mainMenu {
          fieldGroupName
          language
          menu
          title
          url
          country
        }
      }
    }
  }
`;

const getMenuByNameQuery = gql`
  query getMenuByName($id: ID!, $language: String) {
    menu(id: $id, idType: NAME) {
      menuItems(where: { parentDatabaseId: 0 }) {
        nodes {
          id
          uri
          label
          childItems {
            nodes {
              id
              uri
              label
              connectedObject {
                ... on Page {
                  slug
                }
              }
            }
          }
          connectedObject {
            ... on Page {
              slug
            }
          }
        }
      }
    }
    pages(first: 99, where: { wpmlLanguage: $language }) {
      nodes {
        uri
      }
    }
  }
`;

const menuName = localStorage.getItem(Keys.MenuName) || "helveng_ch_de";
const language = localStorage.getItem(Keys.Language) || "de";
const country = localStorage.getItem(Keys.Country) || "ch";

const getCountriesOfOperation = (optionsData: Options) => {
  return optionsData?.acfOptionsOptions?.Options?.mainMenu ?? [];
};

const getAllCountries = (optionsData: Options) => {
  return optionsData?.acfOptionsOptions?.Options?.countries ?? [];
};

const getLanguageMenus = (dataItems: Data) => {
  return {
    nodes: dataItems ? dataItems.menu.menuItems.nodes : [],
  };
};

const getCountryInfo = (optionsData: Options) =>
  optionsData?.acfOptionsOptions?.Options?.countries?.find(
    (element: CountryInfo) => element?.country === country,
  ) as CountryInfo;

const getOrganizationInfo = (optionsData: Options) => {
  const currentCountryInfo = getCountryInfo(optionsData);

  return currentCountryInfo?.languages?.find((item) => item?.language === language);
};

const getLanguagePages = (dataItems: Data, optionsData: Options) => {
  const pages = [DefaultHome];

  if (dataItems) {
    dataItems.menu?.menuItems?.nodes?.forEach((node) => {
      pages.push(node.uri);

      node.childItems?.nodes?.forEach((child) => {
        pages.push(child.uri);
      });
    });
  }

  const organization = getOrganizationInfo(optionsData);

  if (organization?.impressum) {
    pages.push(organization.impressum);
  }
  if (organization?.termsConditions) {
    pages.push(organization.termsConditions);
  }

  return pages;
};

const useMenu = () => {
  const [fetchMenu, { data: menuData, loading: loadingMenu, error: errorMenu }] =
    useLazyQuery(getMenuByNameQuery);
  const [fetchOptions, { data: optionsData, loading: loadingOptions, error: errorOptions }] =
    useLazyQuery(getOptionsQuery);

  const getMenus = useCallback(() => {
    fetchOptions().then(({ data: options }: { data: Options }) => {
      let currentMenu = options.acfOptionsOptions.Options.mainMenu.find?.(
        (option) => option.menu === menuName && option.language === language,
      );

      if (!currentMenu) {
        [currentMenu] = options.acfOptionsOptions.Options.mainMenu;
      }

      fetchMenu({
        variables: {
          id: currentMenu.menu,
          language: currentMenu.language,
        },
      });
    });
  }, [fetchMenu, fetchOptions]);

  return {
    getMenus,
    data: {
      menuItems: getLanguageMenus(menuData),
      organization: getOrganizationInfo(optionsData),
      pages: getLanguagePages(menuData, optionsData),
      countriesOfOperations: getCountriesOfOperation(optionsData),
      allCountries: getAllCountries(optionsData),
    },
    isLoading: loadingMenu || loadingOptions,
    error: errorMenu ?? errorOptions,
  };
};

export default useMenu;
