import { createTheme, ThemeOptions } from "@mui/material/";

export const fontWeight: Record<string, string> = {
  light: "300",
  regular: "400",
  medium: "500",
  bold: "700",
};

const theme = createTheme();

export const themeConfig: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 1240,
      lg: 1440,
      xl: 2000,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          height: "100%",
          "& #root": {
            minHeight: "100%",
          },
        },

        a: {
          color: "#000",
          fontWeight: 700,
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === "outlined") {
            return {
              color: "#000",
              backgroundColor: "#F8F8F8",
              borderRadius: "10px",
              border: "1px solid #000",
              fontWeight: fontWeight.bold,
              lineHeight: "18px",
              padding: "8px 16px",
              height: "36px",

              "&:focus": { backgroundColor: "#00000040", border: "1px solid #000" },

              "&:hover": {
                backgroundColor: "#0000001a",
                border: "1px solid #000",
              },
            };
          }
          if (ownerState.variant === "text") {
            return {
              color: "#000",
              fontWeight: fontWeight.bold,
              lineHeight: "18px",
              padding: "0",
              height: "19px",
              textDecoration: "underline",

              "&:focus": { backgroundColor: "transparent" },

              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            };
          }

          if (ownerState.variant === "contained") {
            return {
              color: "#FFF",
              fontSize: "16px",
              fontWeight: fontWeight.bold,
              lineHeight: "19px",
              height: "100%",
              padding: "15px 32px",
              borderRadius: "10px",

              ".Mui-disabled": {
                backgroundColor: "red",
              },

              "&.Mui-disabled": {
                backgroundColor: "transparent",
                border: "1px solid #00000033",
                color: "#00000033",
              },
            };
          }

          return {};
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: { root: { color: "#000", "&.Mui-checked": { color: "#000!important" } } },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontWeight: fontWeight.regular,
          fontSize: "20px",
          lineHeight: "23px",
          letterSpacing: 0.2,

          "&.Mui-selected": {
            color: "#000000",
            fontWeight: fontWeight.bold,
            fontSize: "20px",
            lineHeight: "23px",
            letterSpacing: 0,
          },
          ":hover": {
            fontWeight: fontWeight.bold,
            letterSpacing: 0,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          ".MuiTabs-indicator": {
            backgroundColor: "#7EF0CF",
            height: "6px",
          },
          "& button": {
            padding: "10px 2px 4px 2px",
            margin: "0 22px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": { color: "#00000033" },
          "& label.Mui-focused": {
            color: "#A5B1BD",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "1px solid #000",
          },
          "& .MuiInputBase-root:hover:before": {
            borderBottom: "1px solid #000000de!important",
          },
          "& input:valid:focus ": {
            borderBottom: "1px solid #000000!important",
          },
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: "16px",
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.medium,
      lineHeight: "24px",
      letterSpacing: "0.1px",
    },
    h1: {
      fontSize: 60,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.bold,
      lineHeight: "70px",
      [theme.breakpoints.down("md")]: {
        fontSize: 50,
      },
    },
    h2: {
      fontSize: 40,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.bold,
      lineHeight: "47px",
      [theme.breakpoints.down("md")]: {
        fontSize: 30,
      },
    },
    h3: {
      fontSize: 32,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.bold,
      lineHeight: "38px",
      [theme.breakpoints.down("md")]: {
        fontSize: 22,
      },
    },
    h4: {
      fontSize: 26,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.bold,
      lineHeight: "30px",
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
      },
    },
    h5: {
      fontSize: 16,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.bold,
      lineHeight: "24px",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
    body1: {
      fontSize: 16,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.regular,
      lineHeight: "24px",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
    body2: {
      fontSize: 16,
      fontFamily: '"Roboto"',
      fontWeight: fontWeight.regular,
      lineHeight: "24px",
      color: "#222222",
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
    },
  },
};
