export const loadState = (item) => {
  try {
    const serialState = localStorage.getItem(item);

    if (serialState === null) {
      return undefined;
    }

    return JSON.parse(serialState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (item, state) => {
  try {
    const serialState = JSON.stringify(state);

    localStorage.setItem(item, serialState);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
