/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "./localStorage";

const REDUCER_STATUS = {
  NOT_INITIATED: "not_initiated",
  IDLE: "idle",
  LOADING: "loading",
};

const defaultState = {
  status: REDUCER_STATUS.NOT_INITIATED,
  user: {
    token: null,
    expireOn: null,
    userID: null,
    userName: null,
    userRole: null,
    loggedInUser: null,
  },
};

const loadStorageUser = () => {
  const persistedState = loadState();

  if (persistedState) {
    const time = new Date().getTime() / 1000;

    if (persistedState.expireOn > time) {
      return persistedState;
    }
  }
  // saveState(defaultState);

  return defaultState;
};

export const userSlice = createSlice({
  name: "user",
  initialState: loadStorageUser(),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    logout: (state) => {
      state.user = null;
    },
  },
});

export const userId = (state) => state.user?.userId;
export const userName = (state) => state.user?.userName;
export const userRole = (state) => state.user?.userRole;
export const userLang = (state) => state.user?.lang;
export const token = (state) => state.user?.user.token;
export const expireOn = (state) => state.user?.user.expireOn;

export const initiated = (state) => !(state.user?.status === REDUCER_STATUS.NOT_INITIATED);
export const loading = (state) => state.user?.status === REDUCER_STATUS.LOADING;

export default userSlice.reducer;
